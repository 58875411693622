.canvasWrapper {
    height: 0;
    overflow: hidden;
    background: red;
    position: relative;
    max-width: 720px;
    padding-top: 90%;
    
    margin:auto;
    margin-top:20px;
    padding-left:20px;
    padding-right:20px;
    
    video {
        position: absolute;
        left:0px;
        right:0px;
        top:0px;
        display: block;
        margin:auto;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index:0;
    }
    .canvas-container {
        position: absolute;
        z-index: 1;
        left:0px;
        top:0px;
    }
}

.shoutout_filter_display {
    // max-width: 300px;
    margin: 0 auto;
    position: relative;
    .shoutout_filter_display_img {
        line-height: 0;
        

    }
    .shoutout_filter_display_filterImg {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.shoutout_filter_frame {
    max-width: 220px;
    margin: 0 auto;
    position: relative;
    padding: 20px 0 0 0;
    .shoutout_filter_frame_slider {
        background-color: #F5F7F9;
        padding: 10px;
        border-radius: 10px;
        .frame_box {
            line-height: 0;
            padding: 5px;
            cursor: pointer;
        }
    }
}
.shoutout_filter_action {
    padding: 30px 0 0 0;
    .shoutout_filter_action_btn {
        padding: 0 0 40px 0;
        .btn {
            margin: 0 5px;
        }
    }
    > a {
        color: #808080;
        font-weight: 600;
    }
}

