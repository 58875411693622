.shoutout_exprience_camra_content {
	position: relative;
	overflow: hidden;
	padding-top: 43%;
	width: 1100px;
	margin: auto;
	border-radius: 50px;
}

.shoutout_exprience_camra {
    align-items: flex-start;
		
	
		.shoutout_content_section { 
			width: 100%;
			position: relative;
			height: 100vh;
			display: flex;
			padding-bottom:30px;
		}

		.shoutout_camra {
			width: 100%;
			background-color: #85bdf3;
			position: absolute;
			text-align: center;
			top: 0;
			height: 100%;
    
			.frame { 
					position: relative; 
					left:0px; 
					right:0px; 
					top:0px; 
					bottom:0px; 
					height: 100%; 
					opacity: 1; 
					margin: auto; 
					display: block;
					z-index: 1;
			}
	
			video {
					width: 100%;
					max-width: 100%;
					max-height: 100%;
					position: absolute; 
					left:0px; 
					right:0px; 
					top:0px; 
					bottom:0px; 
			}
	
			.shoutout_camra_bg {
					background-size: cover;
					background-position: center;
					background-repeat: no-repeat;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 100%;
					text-align:center;
	
					img {
							height:100%;
					}
			}

			.shoutout_camra_wraps {
                position: relative;
                height: 100%;
            }
            .shoutout_camera_placeholder {
                display: none;
                width: 100%;
                // height: 360px;
                aspect-ratio: 1 / 1;
                position: absolute;
                left: 0;
                top: 88px;
                border: 6px solid #FF0200; 
                z-index: 99;
                img {
                    width: 200px;
                    position: relative;
                }
            }
	}
}

.device_iphone {
    .shoutout_camra  {
        .shoutout_camera_placeholder {
            aspect-ratio: 4/5;
            img {
                width: auto;
                aspect-ratio: 1/1;
            }
        }
    }
}


.shoutout_camra_action {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    max-width: 420px;
    right: 0;
    margin: auto;
    z-index: 3;

    .shoutout_camra_action_btnGroup {
        display: flex;
        padding: 0 10px;
        width: 100%;
        flex-wrap: wrap;
        .shoutout_camra_action_btnGroup_left {
            width: 30%;
        }
        .shoutout_camra_action_btnGroup_right {
            display: flex;
            justify-content: flex-end;
            width: 70%;
        }
        

    }
}
.shoutout_camra_action_btn {
    margin: 0;
    position: relative;
    .btn {
        width: 50px;
        height: 50px;
        line-height: 1;
        padding: 0;
        border-radius: 50%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        border-color: transparent;
        position: relative;
        transition: all 0.2s ease-out;
        &:hover {
            transform: scale(1.1);
            background-color: rgba(0, 0, 0, 1);
        }
        svg, img {
            max-height: 20px;
            max-width: 30px;
        }
        input[type="file"] {
            display: none;
        }
    }
    label.btn {
        line-height: 56px;
        svg, img {
            max-height: 22px;
            max-width: 30px;
        }
    }
    span {
        position: absolute;
        min-width: 150px;
        left: -45px;
        bottom: -32px;
        background-color: #000;
        color: #fff;
        text-align: center;
        padding: 6px 3px;
        font-size: 11px;
        border-radius: 20px;
        display: none;
    }
    ul {
        position: absolute;
        min-width: 180px;
        left: -45px;
        top: 65px;
        background-color: #fff;
        padding: 0;
        z-index: 9;
        display: none;
        
        li {
            color: #000;
            display: block;
            padding: 7px 10px;
            border-bottom: 1px solid #eee;
            font-size: 12px;
            &:hover {
                color: $primary;
            }
        }
    }
    &.camra_btn_Vedio {
        .btn {
            svg, img {
                max-width: 22px;
            }
        }
    }
    // &:hover {
    //     span {
    //         display: block;
    //     }

    // }
    &.active {
        ul {
            display: block;
        }
        
    }
}
.shoutout_recording_action {
    position: absolute;
    max-width: 420px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
    z-index: 100;

    .Shoutout_question_txt {
        margin-bottom: 8px;
        padding: 0 16px;
        h3 {
            font-size: 24px;
            color: #F39C12;
            line-height: 32px;
        }
        h5 {
            color: #fff;
        }
    }
    .shoutout_recording_txt {
        text-align: center;
        color: #fff;
        .shoutout_recording_txt_start {
            font-weight: 600;
            font-size: 22px;
            display: none;
            span {
                color: #ed1c24;
            }
        }
        .countdown {
            font-weight: 600;
            font-size: 16px;
        }
    }
    .shoutout_recording_action_btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 16px 0 0 0;
        .shoutout_recording_action_btn_width {
            width: 60px;
        }
    }
    
		.shoutout_action_btn_note {
        width: 80px;
    }

    .shoutout_action_btn_record {
        padding: 0 30px;
        position: relative;

        .btn_recording  {
            width: 68px;
            height: 68px;
            padding: 0;
            border-radius: 50%;
            text-align: center;
            transition: all 0.2s ease-out;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 2px;

            &:hover {
                transform: scale(1.1);
            }

            span {
                content: "";
                position: absolute;
                width: 38px;
                height: 38px;
                border-radius: 6px;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #fff;
            }
        }
    }
		
    .shoutout_action_btn_switch {
        // width: 80px;
        // text-align: right;
        .btn {
            width: 60px;
            height: 60px;
            padding: 0;
            line-height: 65px;
            border-radius: 50%;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.4);
            border-color: transparent;
            position: relative;
            transition: all 0.2s ease-out;
            &:hover {
                transform: scale(1.1);
                background-color: rgba(0, 0, 0, 1);
            }
            svg, img {
                max-height: 20px;
                max-width: 30px;
            }
        }
    }
}


.switch-radio {
  position: relative;
  display: inline-block;
}

.switch-radio-input {
  display: none;
}
.switch-radio-label {
    display: block;
    width: 80px;
    height: 36px;
    text-indent: -150%;
    clip: rect(0 0 0 0);
    color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}
.switch-radio-label:before,
.switch-radio-label:after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-radio-label:before {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 9999em;
    transition: background-color 0.25s ease;
    border: 1px solid #C4C4C4;
}
.switch-radio-label:after {
    top: 4px;
    left: 4px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #C4C4C4;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
    transition: left 0.25s ease;
}
/* When input is checked */
.switch-radio-input:checked + .switch-radio-label:before {
  background-color: #ffffff;
}
.switch-radio-input:checked + .switch-radio-label:after {
  left: 48px;
  background-color: #28a745;
}
.video_timer {
    width: 120px;
    height: 120px;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    background: #ED1C24;
    text-align: center;
    line-height: 120px;
    margin: 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    .btn_recording_stop {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        display: block;
        padding: 0;
        background-color: transparent;
        &:after {
            content: "";
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 6px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: #fff;
        }
    }
}
.video_timer .video_overlay {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ED1C24
}

// .video_timer .video_left, .video_timer .video_right {
//     width: 50%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     border: 7px solid #ffffff;
//     border-radius: 100px 0px 0px 100px;
//     border-right: 0;
//     transform-origin: right;
// }

// .recording_active .video_timer .video_left {animation: load1 60s linear forwards;}

// .recording_active .video_timer .video_right {animation: load2 60s linear forwards 60s;}

// @keyframes load1 {
//     0% {transform: rotate(0deg);}
//     100% {transform: rotate(180deg);}
// }

// @keyframes load2 {
//     0% {z-index: 100;transform: rotate(180deg);}
//     100% {z-index: 100;transform: rotate(360deg);}
// }


.shoutout_disable_screen {
    position: fixed;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 9999;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .shoutout_disable_screen_box {
        svg, img {
            width: 80px;
            height: 100%;
            animation: svgrotation 2.5s infinite linear;
            -webkit-animation: svgrotation 2.5s infinite linear;
        }
        h6 {
            margin: 0;
            padding: 10px 0 0 0;
        }
    }
}

@keyframes svgrotation {
  0% {
    transform: rotate(0deg);
  }
    50% {
    transform: rotate(-94deg);
  }
  100% {
    transform: rotate(0);
  }
}


.shoutout_camra_start_counter {
    width: 100%;
    // height: 100vh;
    // position: absolute;
    top: 0;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .count_num {
        font-size: 120px;
        span {
            font-weight: 600;
            font-size: 100px;
        }
    }
}
.recording_active {
    .video_timer {
        visibility: visible;
    }
    .btn_recording {
        opacity: 0;
    }
}

.shoutout_note_wrap {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: rgba(127, 0, 255, 0.8);
    z-index: 9;
    padding: 120px 50px;
    .shoutout_note_form {
        padding-bottom: 50px;
        .form-control {
            resize: none;
            background-color: transparent;
            border: 0;
            box-shadow: none;
            color: #fff;
            font-size: 48px;
            font-weight: 400;
            text-shadow: 1px 1px 1px #999;
            text-align: center;
            padding: 0;
            height: 500px;
        }
    }
    .shoutout_note_btn {
        text-align: center;
        .btn {
            margin: 0 5px;
        }
    }
}

.shoutout_camra_frame {
    // width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    display: inline-block;
    width: fit-content;
    text-align:center;
        
    &:before {
        content: "";
        left: -200%;
        top: 0;
        position: absolute;
        // width: calc((100vw - 100vh) / 1.7);
        width: 200%;
        height: 100%;
        background: rgba(227, 23, 78, 0.3);
        z-index: 2;
    }
    &:after {
        content: "";
        right: -200%;
        top: 0;
        position: absolute;
        // width: calc((100vw - 100vh) / 1.7);
        width: 200%;
        height: 100%;
        background: rgba(227, 23, 78, 0.3);
        z-index: 2;
    }
}

.actionButtonWrap {
    position: relative;
    width: 100px;
}
.actionButtonWrap button {
    margin: 16px;
    position: relative;
    z-index:1;
}
.actionButtonWrap svg, .actionButtonWrap img {
    position: absolute;
    z-index:0;
}

.device_iphone {
    .shoutout_recording_action {
        // bottom: 150px;
        bottom: 20px;
        position:fixed;
    }
}

.device_iphone.text {

    .shoutout_camra_wraps {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .shoutout_exprience_camra .shoutout_camra .frame {
        height: auto;
    }
    .shoutout_camra_frame {
        height: auto;

        &:before {
            height: 100%;
            width: 100%;
            left: 0;
            top: -100%;
        }
        &:after {
            height: 100%;
            width: 100%;
            right: 0;
            top: 100%;
        }
    }
}


.shoutout_disable_camra {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 9999;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0;
    .shoutout_disable_camra_box {
        svg, img {
            width: 80px;
            height: 100%;
            fill: #fff;
        }
        h6 {
            margin: 0;
            padding: 15px 0;
        }
    }
}

@media only screen and (max-width:1920px) {
    .shoutout_exprience_camra_content {
        width: 1470px;
    }
}
@media only screen and (max-width:1800px) {
    .shoutout_exprience_camra_content {
        width: 1380px;
    }
}
@media only screen and (max-width:1700px) {
    .shoutout_exprience_camra_content {
        width: 1300px;
    }
}
@media only screen and (max-width:1600px) {
    .shoutout_exprience_camra_content {
        width: 1225px;
    }
}
@media only screen and (max-width:1440px) {
    .shoutout_exprience_camra_content {
        width: 1100px;
    }
}
@media only screen and (max-width:1380px) {
    .shoutout_exprience_camra_content {
        width: 1055px;
    }
}
@media only screen and (max-width:1200px) {
    .shoutout_exprience_camra_content {
        width: 920px;
    }
}




@media screen and (max-width: 991px) {
    .shoutout_exprience_camra_content {
        padding-top: 0px;
        border-radius: 0px;
        width: 100%;
        height: 100%;
    }

    .shoutout_exprience_camra {    
        .shoutout_content_section { 
            padding-bottom: 0px;
            min-height: 100vh;
            min-height: stretch;
            min-height: -webkit-stretch;
        }
    }
}

@media screen and (max-width: 680px) {
    .shoutout_camra_frame:before {
        left: 0 !important;
        background: linear-gradient(0deg, rgb(0 0 0 / 70%) 0%, rgba(0, 0, 0, 0) 80%) !important;
    }
    .shoutout_recording_action .Shoutout_question_txt {
        text-align: left;
    }
    .shoutout_recording_action .Shoutout_question_txt h3 {
        font-size: 22px;
    }
    .shoutout_recording_action .Shoutout_question_txt h5 {
        font-size: 16px;
        line-height: 1.5;
    }
    .shoutout_note_wrap {
        padding: 50px 20px;
    }
    .shoutout_note_wrap .shoutout_note_form .form-control {
        font-size: 38px;
    }

    .shoutout_recording_action {
        bottom: 60px;
    }

   
    .shoutout_exprience_camra {
        .shoutout_copyright {
            display: none;
        }
    }
    .shoutout_camra_action .shoutout_camra_action_btnGroup .shoutout_camra_action_btn {
        margin: 0 5px;
    }
    .shoutout_camra_action {
        top: 20px;
    }
    .shoutout_camra_start_counter .count_num span {
        font-size: 60px;
    }

    .shoutout_exprience_camra_content {
        padding-top: 0px;
        border-radius: 0px;
        width: 100%;
        height: 100%;
    }

    .shoutout_exprience_camra {    
        .shoutout_content_section { 
            padding-bottom: 0px;
            min-height: 100vh;
            min-height: stretch;
            min-height: -webkit-stretch;
        }
    }
    .shoutout_recording_action_btn {
        .shoutout_recording_action_btn_width {
            .btn {
                width: 48px !important;
                height: 48px !important;
                line-height: 53px !important;
                background-color: rgba(0,0,0,.4) !important;
                img {
                    max-height: 20px;
                    max-width: 22px;  
                }
            }
        }
    }

}

@media only screen and (max-width: 480px) {
    // .shoutout_camera_placeholder {
    //     display: flex !important;
    //     align-items: center;
    //     justify-content: center;
    // }

    // .shoutout_camra_wraps:before {
    //     content: "";
    //     width: 100%;
    //     height: 340px;
    //     position: absolute;
    //     left: 0;
    //     top: 88px;
    //     border: 6px solid #FF0200;
    // }
}
@media only screen and (max-width: 380px) {
    // .device_iphone .shoutout_camra .shoutout_camera_placeholder {
    //     aspect-ratio: auto;
    //     height: 320px;
    // }
    // .device_iphone .shoutout_camra .shoutout_camera_placeholder img {
    //     width: 200px;
    //     aspect-ratio: auto;
    // }
}


//Oriented Screen
// @media screen and (max-height: 480px) {
//     .shoutout_camra_action .shoutout_camra_action_btnGroup {
//         display: block;
//         justify-content: center;
//         width: 70px;
//         padding: 0;
//         .shoutout_camra_action_btn {
//             margin: 10px 10px;
//         }
//     }
//     .shoutout_camra_action .shoutout_camra_action_btnGroup .shoutout_camra_action_btnGroup_left {
//         width: 100%;
//     }
//     .shoutout_camra_action .shoutout_camra_action_btnGroup .shoutout_camra_action_btnGroup_right {
//         display: block;
//         justify-content: center;
//         width: 100%;
//     }
//     .shoutout_exprience_camra {
//         .shoutout_copyright {
//             display: none;
//         }
//     }
//     .shoutout_recording_action {
//         bottom: 0;
//         top: 0;
//         max-width: 100%;
//         z-index: 99;
//     }
//     .shoutout_recording_action .shoutout_recording_action_btn {
//         padding: 0;
//         width: 180px; 
//         height: 100%;
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
//     .shoutout_recording_action .shoutout_recording_txt .shoutout_recording_txt_start {
//         padding: 30px 0 0 0;
//     }
//     .shoutout_recording_action .shoutout_recording_txt .countdown {
//         padding: 10px 0 0 0;
//     }
//     .shoutout_note_wrap {
//         padding: 20px 20px;
//     }
//     .shoutout_note_wrap .shoutout_note_form .form-control {
//         height: 65vh;
//     }
//     .shoutout_camra_action {
//         top: 80px;
//         z-index: 100;
//         max-width: 100%;
//     }

// }