.column_overlap {
    .shoutout_content_section {
        > .shoutout_header_logo {
            display: none;
        }
        .shoutout_content_wrap {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
.shoutout_welcome {
    .shoutout_welcome_btn {
        padding: 40px 0;
        button {
            border-radius: 36px;
            min-width: 250px;
            margin: 0 auto;
            &:first-child {
                margin-bottom: 16px;
            }
        }
    }
    .welcome_play_btn {
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 16px auto 24px;
            // min-height: 60px;
            position: relative;
            width: 94px;
            height: 94px;
            border-radius: 100%;
            background-color: #FF0000 !important;
            span {
                display: block;
                line-height: 1.5;
                text-align: left;
                margin-left: 16px;
                small {
                    display: block;
                    line-height: 1;
                }
            }
            &::before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;
                display: block;
                width: 100%;
                height: 100%;
                background: inherit;
                border-radius: 50%;
                -webkit-animation: pulse-border 1500ms ease-out infinite;
                animation: pulse-border 1500ms ease-out infinite;
            }
        }
    }
}

// .play-video {
//     .shoutout_welcome {
//         .shoutout_header_logo, h3, h6, .shoutout_welcome_btn {
//             display: none !important;
//         } 

//     }
// }


@media only screen and (max-width: 992px) {
    .shoutout_two_column.column_overlap .shoutout_header_logo {
        position: relative;
        background-color: transparent;
        height: auto;
        margin-bottom: 8px;
    }
    .shoutout_two_column.column_overlap .shoutout_video_dispaly {
        display: block;
    }
    .shoutout_two_column.column_overlap .shoutout_content_section {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
        background-color: transparent !important;
    }
    .shoutout_welcome {
        max-width: 450px;
        margin: 0 auto;
    }
    .shoutout_welcome .shoutout_welcome_btn {
        padding: 20px 0;
    }
    
}


@keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
      opacity: 0;
    }
}