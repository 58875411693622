.camra_btn_Vedio {
    ul {
        li {
            cursor: pointer;
        }
    }
}

body {
    
    .canvas-container {
        display:block;
        margin:auto;
    }

    .slick-slide {
        min-height: 50px;

        h1 {
            font-size: 56px;
            line-height: 56px;
            font-size: 48px !important;
        }
    }

    .slick-dots {
        bottom: -20px;
        

        li {
            margin:0px;
            width: auto;
            height: auto;

            button { 
                font-size: 0;
                line-height: 0;
                display: block;
                width: 20px;
                height: 4px;
                padding: 0;
                cursor: pointer;
                color: transparent;
                outline: none;
                background: #ECECEC;
                border-radius: 3px;
                border: 0;
        
                &:after, &:before  {
                    display: none;
                }
            }
    
            &.slick-active button {
                opacity: 1;
                background-color: #D3D3D3;
                border: 1px solid #D3D3D3;
            }
        }
    }
}