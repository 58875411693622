.shoutout_crop_wrap {
    width: 86%;
    max-width: 400px;
    height: 100vh;
    z-index: 9;
    padding-top:20px;
    margin:auto;
    text-align: center;

    .ReactCrop {
        max-height: 85vh;
    }

    .buttonWrap {
        margin-top: 10px;
        text-align: center;

        button {
            margin:5px;
        }
    }
}



@media screen and (max-width: 968px) {
   
}

@media screen and (max-width: 680px) {

}

@media screen and (max-width: 480px) {
    
}   