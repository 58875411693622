.shoutout_invitation {
    .shoutout_content_section {
        width: 50%;
    }
}

.shoutout_invitation_form {
    h1 {
        padding-bottom: 0;
    }
    .play_btn {
        display: none;
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 992px) {
    .shoutout_invitation {
        .shoutout_content_box {
            height: 455px;
        }
        .shoutout_header_logo {
            .shoutout_logo_dark {
                display: none;
            }
            .shoutout_logo_light {
                display: block;
            }
        }
        .shoutout_content_section {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            height: 100%;
            width: 100%;
        }

        .shoutout_invitation_form {
            padding-top: 16px;
            h1, h2, h3, h4, h5, h6, p {
                color: #fff;
            }
            .play_btn {
                display: inline-block;
            }
        }
    }

    .shoutout_invitation {
        .shoutout_header_logo {
            padding: 10px 0;
        }
        .shoutout_content_section {
            display: flex;
        align-content: flex-end;
            flex-wrap: wrap;
        }
        .shoutout_header_logo {
            width: 100%;
        }
        .shoutout_content_box {
            height: auto !important;
            position: static !important;
            width: 100%;
            max-width: 100%;
            padding: 0 20px 50px 20px;
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 100%);
        }
        .shoutout_copyright {
            background-color: transparent;
        }
    }

}
@media screen and (max-width: 680px) {
    // .shoutout_invitation .shoutout_content_box {
    //     bottom: 70px;
    //     top: auto;
    // }
}
// Oriented Screen
// @media screen and (max-height: 480px) {
//     .shoutout_invitation {
//         .shoutout_content_section {
//             width: 50%;
//             position: relative;
//             height: 100vh;
//         }
//         .shoutout_header_logo {
//             .shoutout_logo_dark {
//                 display: block;
//             }
//             .shoutout_logo_light {
//                 display: none;
//             }
//         }
//         .shoutout_content_box {
//             height: 165px;
//         }
//         .shoutout_invitation_form {

//             .play_btn {
//                 display: none;
//             }
//             form {
//                 display: flex;
//                 flex-wrap: wrap;
//                 .form-group {
//                     width: 50%;
//                     padding: 0 10px;
//                     &:last-child {
//                         width: 100%;
//                     }
//                 }
//             }
//         }
//     }
// }