.shoutout_text_wrap {
    // width: 100%;
    // height: 100vh;

    // background-color: rgba(127, 0, 255, 0.8);
    z-index: 9;
    padding: 64px 16px 0px;

    .back_btn {
        position: absolute;
        left: 20px;
        top: 20px;
        .btn {
            border: none !important;
            background-color: transparent !important;
            box-shadow: none !important;
            &:focus {
                box-shadow: none !important;
                border: none !important;
                background-color: transparent !important;  
            }
            &:active {
                box-shadow: none !important;
                border: none !important;
                background-color: transparent !important;   
            }
        }
    }
    
    .shoutout_text_heading {
        max-width: 500px;
        margin: 0 auto 32px;
    }
    .shoutout_answer_box {
        max-width: 500px;
        margin: 0 auto;
        .btn  {
            margin-top: 32px;
        }
    }
    .shoutout_text_form {
        max-width: 500px;
        min-height: 500px;
        margin:auto;
        position:relative;
        font-size: 0px;
        margin-bottom: 30px;

        

        .contentWrap {
            position: absolute;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            padding-bottom: 50px;
            padding: 40px 30px 30px;

            h4 {
            
                color: #fff;
            }

        }

        .form-control {
            border-radius: 5px;
            caret-color: red;
            resize: none;
            box-shadow: none;
            color: #000;
            padding: 10px;
            background-color: rgba(255, 255, 255, 1);
            border: 0;
            text-align: center;
            font-size: 16px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            &::-webkit-input-placeholder { 
              color: rgba(0, 0, 0, 0.7);
            }
            &::-moz-placeholder { 
              color: rgba(0, 0, 0, 0.7);
            }
            &:-ms-input-placeholder { 
              color: rgba(0, 0, 0, 0.7);
            }
            &:-moz-placeholder { 
              color: rgba(0, 0, 0, 0.7);
            }
        }

        .backgroundPlaceholder {
            position: relative;
            left:0;
            top:0;
            width:100%;
            z-index:0;
            font-size: 48px;
            font-weight: 400;
        }

        .shoutoutProfilePlaceholder {
            position: relative;
            z-index: 1;
            width: 35%;
            margin-bottom: 5%;
            margin-top: 6%;
        }

        .messageTextarea {
            position: relative;
            z-index: 1;
            height: 180px;
            margin: 25px 0px 5px 0px;
            font-size: 20px;
            box-shadow: none;
            border:none;
            text-align: left;
        }

        .textAreaWrapper {
            
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 15px;
            border-radius: 5px;

            .char_counter {
                padding:10px;
                margin:2%;
                padding:2%;
                width:96%;
                color: #000;
                font-size: 14px;
                border-radius: 0px;
                box-shadow: none;
                border: 0;
                text-align: right;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
        }

        .firstNameText {
            position: relative;
            z-index:1;
            font-size: 18px;
            font-weight: 600;
            height: 30px;
        }

        .lastNameText {
            position: relative;
            z-index: 1;
            font-size: 18px;
            font-weight: 600;
            height: 30px;
        }   

        .firstNameText, .lastNameText {
            width:50%;
            display: inline;
        }
        
        .firstNameText {
            text-align:right;
            padding-right: 10px;
        }

        .lastNameText {
            text-align:left;
        }

        .fullNameText {
            margin-bottom: 15px;
            text-align: left;
        }

        .designationText {
            position: relative;
            z-index: 1;
            text-align: left;
            // font-size: 14px;
            // font-weight: 400;
            // height: 25px;
        }
    }

    .shoutout_note_btn {
        
        max-width: 500px;
        margin: auto;
        position: relative;
        text-align: center;
        .note_btn_back {
            width: 50px;
            height: 50px;
            line-height: 1;
            padding: 0;
            border-radius: 50%;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.7);
            border-color: transparent;
            position: relative;
            transition: all 0.2s ease-out;
            svg {
                max-height: 20px;
                max-width: 30px;
            }
            &:hover {
                transform: scale(1.1);
                background-color: black;
            }
        }


    }
}

/*
Tour Modal style
*/

body .tour-main-wrap {
    padding: 0px;
    max-width: 480px !important;
    min-width: 420px !important;
    background-color: transparent  !important;
    box-shadow: none !important;

    .tour-item-wrap {
        padding: 20px;
        text-align: center;
        color: #fff;
        max-width: 440px !important;
        min-width: 320px !important;
        
        .btn {
            border-radius: 5px;
            min-width: 100px;
            margin: 5px;
            min-height: 38px;
            line-height: 38px;
        }
    
        p {
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
}

.modal_crop_img {
    height: 100% !important;
    overflow: hidden !important;
    overflow-y: scroll !important;    
}

@media screen and (max-width: 992px) {

    .shoutout_text_wrap {
        width: 100%;
        height: calc(100vh - 40px);
        overflow-y: scroll;
        padding: 80px 24px 0 !important;
        .shoutout_text_form {

            .contentWrap {
                padding: 30px;

                .messageTextarea {
                    // height: 160px;
                    font-size: 14px;
                    margin-bottom: 10px;
                }
        
                .nameText {
                    font-size: 18px;
                }
    
            }
            
        }

    }
}

@media screen and (max-width: 680px) {
    .shoutout_text_wrap .back_btn .btn {
        padding: 0;
    }

    body .tour-main-wrap {
        min-width: 320px !important;
    }

    .shoutout_text_wrap {
        .shoutout_text_form {
            .contentWrap {
                h4 {
                    margin-bottom: 20px;
                }
            }

            .messageTextarea {
                height: 170px;
                font-size: 14px;
                margin-bottom: 10px;
            }
    
            .nameText {
                font-size: 18px;
            }
    
        }

    }
    .shoutout_text_wrap .shoutout_text_form .shoutoutProfilePlaceholder {
        width: 24%;
    }
    .shoutout_text_wrap .shoutout_text_form .firstNameText {
        height: 20px;
        font-size: 14px; 
    }
    .shoutout_text_wrap .shoutout_text_form .lastNameText {
        height: 20px;
        font-size: 14px;
    }
    // .shoutout_text_wrap .shoutout_note_btn .btn {
    //     padding: 0.375rem 1.5rem;
    // }

    
}

@media screen and (max-width: 480px) {
    .shoutout_text_wrap {
        width: 100%;
        height: calc(100vh - 32px);
        overflow-y: scroll;
        padding: 80px 24px 0 !important;
        
        .shoutout_text_form {
            margin: 0 -16px 30px;
            .contentWrap {
                padding: 16px;
                .messageTextarea {
                    height: 100px;
                    
                }
    
            }
            .backgroundPlaceholder {
                height: 380px;
            }

          
        }
        .shoutout_text_form {
            min-height: 390px;
        }

    }
}   