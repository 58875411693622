.shoutout_canvas {
    height: 100vh;

    .shoutout_content_section {
        width: 100%;
        height: 100%;
    }
    
    .shoutout_content_box {
        // height: 800px;
        max-width: 660px;
        height: 100%;
        position: relative;
        padding-top: 20px;
    }

    .shoutout_copyright {
        position: fixed;
    }
}

.shoutout_canvas_wrap {
    .profile {
        width: 200px;
    }
}

@media screen and (max-width: 992px) {
}
@media screen and (max-width: 680px) {
}

// Oriented Screen
// @media screen and (max-height: 480px) {

// }
