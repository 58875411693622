.shoutout_exprience {
    .shoutout_content_section {
        min-height: 100vh;
    }
    .shoutout_content_box {
        height: 210px;
    }
}

.shoutout_exprience_btnGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.shoutout_exprience_btn {
    width: 120px;
    padding: 10px;
    .btn  {
        width: 100px;
        height: 100px;
        padding: 0 0 0 0;
        border-radius: 50%;
        text-align: center;
        transition: all 0.2s ease-out;
        line-height: 1.3;
        &:hover {
            transform: scale(1.1);
        }
        svg {
            max-height: 30px; 
            max-width: 50px;
        }
        span {
            display: block;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 8px;

        }
    }
    &.exprience_btn_Video {
        .btn {
            svg {
                max-width: 35px;
            }
        }
    } 
}
.shoutout_exprience_upload {
    padding-top: 25px;
    span {
        position: relative;
        color: $primary;
        cursor: pointer;
        overflow: hidden;
        display: inline-flex;
        svg {
            width: 20px;
            position: relative;
            top: 0px;
            margin: 0 0 0 5px;
        }
        input[type="file"] {
            opacity: 0;
            position: absolute;
            background-color: #000;
            left: -50%;
            width: 150%;
            height: 150%;
            z-index: 99;
            cursor: pointer;
        }
    }
}




@media screen and (max-width: 992px) {
  
}
@media screen and (max-width: 680px) {

}

@media screen and (max-width: 480px) {
    .continue_btn_wrap {
        padding-top: 16px;
    }
}

// Oriented Screen
// @media screen and (max-height: 480px) {
//     .shoutout_exprience_btn {
//         width: 100px;
//         padding: 5px;
//         .btn {
//             width: 90px;
//             height: 90px;
//             span {
//                 font-size: 10px;
//             }
//             svg {
//                 max-height: 25px;
//                 max-width: 40px;
//             }
//         }
//     }
//     .shoutout_exprience .shoutout_content_box {
//         height: 160px;
//     }
// }