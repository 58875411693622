.shoutout_audio_box {
    max-width: 500px;
    margin: 0 auto;
    .audio_img {
        margin: 24px 0;
        img {
            width: 100%;
        }
    }
    .audio_heading {
        text-align: center;
    }
}